import React from 'react';
import { useSelector } from 'react-redux';

import type { FileInfo } from '@/store/document.slice';
import { selectContent } from '@/store/editor.slice';

export const isFileLoaded = (maybeFile: FileInfo): maybeFile is Required<FileInfo> =>
  !!maybeFile.tradeReference && !!maybeFile.documentType && !!maybeFile.documentId;

export const useFileLoaded = (maybeFile: FileInfo): maybeFile is Required<FileInfo> => {
  const content = useSelector(selectContent);
  const fileLoaded = React.useMemo(() => isFileLoaded(maybeFile), [maybeFile]);
  return fileLoaded && content !== undefined;
};

export const useFilePresent = (
  maybeFile: FileInfo,
  draftXmlDocument: string | undefined,
): maybeFile is Required<FileInfo> => {
  const fileLoaded = React.useMemo(() => isFileLoaded(maybeFile), [maybeFile]);
  return fileLoaded && draftXmlDocument !== undefined;
};
