import React from 'react';
import { useSelector } from 'react-redux';

import { Conditional } from '@/components/common/Conditional';
import { isIssuingRelatedDocument, selectLocal } from '@/components/common/Modals/SaveAndSendModal';
import { useDocTypeObj } from '@/hooks/useDocType';
import { useLazyPutDocumentQuery } from '@/store/document.api';
import { selectDocument } from '@/store/document.slice';
import { saveNsendDocument } from '@/store/document.thunk';
import { selectEditor } from '@/store/editor.slice';
import { useAppDispatch } from '@/store/store';
import {
  consumed,
  modalOpenState,
  selectFromXOne,
  selectIsConsumed,
  selectLoading,
  selectQueryParams,
  selectRights,
  selectTimedout,
} from '@/store/ui.slice';
import { ButtonWrap, type IRecord } from '../common/RightButtonWrap';

export const RightHeaderButtons = ({ values }: { values: IRecord }) => {
  const dispatch = useAppDispatch();

  const editor = useSelector(selectEditor);
  const file = useSelector(selectDocument);
  const rights = useSelector(selectRights);
  const fromXone = useSelector(selectFromXOne);
  const timedout = useSelector(selectTimedout);
  const { isredraft } = useSelector(selectQueryParams);

  const sendConsumed = useSelector(selectIsConsumed('SaveNSend'));
  const storeConsumed = useSelector(selectIsConsumed('SaveNStore'));
  const chasingConsumed = useSelector(selectIsConsumed('Chasing'));
  const cancelChasingConsumed = useSelector(selectIsConsumed('CancelChasing'));

  const loading = useSelector(selectLoading('SaveNSend'));

  const isIssuing = isIssuingRelatedDocument(file.documentType);

  const {
    Confirmation,
    PreConfirmation,
    ReadOnlyDocument,
    Chasing,
    Affirmation,
    PreMatching,
    FlowAdvice,
    FlowCorrespondence,
    IssuingDocumentation,
    Invoice,
    MRB,
    TermSheet,
    Payment,
    PaymentReminder,
    Fixing,
    FinalTerms,
  } = useDocTypeObj(file.documentType);

  const [triggerSave, responseSave] = useLazyPutDocumentQuery();

  const saveFile = React.useCallback(async () => {
    const { assetClass, tradeReference, documentId, documentType } = file;

    if (assetClass === '' || tradeReference === '' || !documentId || documentType === '') {
      return undefined;
    }

    const currentText = (await editor?.saveToString())?.draftXML;
    if (!currentText) {
      return undefined;
    }

    return triggerSave({
      assetClass,
      tradeReference,
      documentId,
      documentType,
      workingDocument: currentText,
    });
  }, [file, editor, triggerSave]);

  // eslint-disable-next-line consistent-return
  const saveNsend = React.useCallback(async () => {
    const { assetClass, tradeReference, documentId, documentType } = file;

    if (assetClass === '' || tradeReference === '' || !documentId || documentType === '') {
      return undefined;
    }

    const currentText = (await editor?.saveToString())?.draftXML;
    if (!currentText) {
      return undefined;
    }

    dispatch(
      saveNsendDocument({
        assetClass,
        tradeReference,
        documentId,
        documentType,
        workingDocument: currentText,
        actionName: isIssuing ? 'Validate' : 'Send',
        actionComment: '',
      }),
    );
  }, [file, editor, dispatch, isIssuing]);

  return (
    <Conditional condition={rights[1] && fromXone && !file.isReadOnly}>
      <ButtonWrap
        condition={Confirmation || PreMatching || FlowAdvice || isIssuing}
        disabled={timedout}
        loading={responseSave.isLoading || responseSave.isFetching}
        icon="save"
        action={async () => {
          const res = await saveFile();
          if (res?.isSuccess) dispatch(consumed('Save'));
        }}
        label={
          values.labels[1]
            ? `header.button.sav${responseSave.isLoading || responseSave.isFetching ? 'ing' : 'e'}`
            : undefined
        }
        mr={values.mr[0]}
      />

      <ButtonWrap
        condition={(Confirmation && isredraft) || isIssuing}
        disabled={timedout || storeConsumed || sendConsumed}
        icon="move_to_inbox"
        action={() => dispatch(modalOpenState({ modalId: 'SaveNStore', openState: true }))}
        label={values.labels[2] ? 'header.button.save_store' : undefined}
        mr={values.mr[0]}
      />

      <ButtonWrap
        condition={
          (Confirmation && isredraft) ||
          Affirmation ||
          PreMatching ||
          FlowAdvice ||
          TermSheet ||
          FinalTerms ||
          FlowCorrespondence ||
          isIssuing
        }
        disabled={timedout || sendConsumed}
        icon="send"
        action={() => dispatch(modalOpenState({ modalId: 'SaveNSend', openState: true }))}
        label={values.labels[3] ? selectLocal(file.documentType)(false) : undefined}
        btnType="btn-info"
      />
      <ButtonWrap
        condition={PreConfirmation}
        disabled={timedout}
        icon="send"
        loading={loading}
        action={saveNsend}
        label={values.labels[3] ? selectLocal(file.documentType)(false) : undefined}
        loadingLabel={selectLocal(file.documentType)(true)}
        btnType="btn-info"
      />

      <ButtonWrap
        condition={Chasing}
        disabled={timedout || chasingConsumed || cancelChasingConsumed}
        icon="block"
        action={() =>
          dispatch(
            modalOpenState({
              modalId: 'CancelChasing',
              openState: true,
            }),
          )
        }
        label={values.labels[1] ? 'header.button.cancelchasing' : undefined}
        mr={values.mr[0]}
      />
      <ButtonWrap
        condition={Chasing}
        disabled={timedout || chasingConsumed || cancelChasingConsumed}
        icon="send"
        action={() => dispatch(modalOpenState({ modalId: 'Chasing', openState: true }))}
        label={
          values.labels[2]
            ? `header.button.chas${responseSave.isLoading || responseSave.isFetching ? 'ing' : 'e'}`
            : undefined
        }
        btnType="btn-info"
        mr={values.mr[0]}
      />
    </Conditional>
  );
};
