import { createEditor, getV3, loadSmartDxDraftEditorLibrary, setTheme, setUpSmartDx } from '@/helpers/SmartDXWrap';
import { logger } from '@/helpers/logger';
import { setEditor } from '@/store/editor.slice';
import { useGetFrontConfigQuery } from '@/store/presentation.api';
import { curry } from '@bhc/ts-tools';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useLoadSmartDxEditor = (rights: [boolean, boolean, boolean, boolean]) => {
  const dispatch = useDispatch();

  const { data: FrontConfig } = useGetFrontConfigQuery();

  const smartDXData = FrontConfig?.smartDxData;

  useEffect((): (() => void) | undefined => {
    if (smartDXData) {
      loadSmartDxDraftEditorLibrary(smartDXData.scriptSrc)
        .then(setUpSmartDx)
        // second permission is set to false because we are preview / read only mode
        .then(curry(createEditor, rights, smartDXData ?? ''))
        .then(getV3)
        .then((editor) => setTheme(editor, smartDXData?.theme ?? ''))
        .then((editor) => dispatch(setEditor(editor)))
        .catch((error) => logger.logError(`loading script failed: ${error}`));

      return () => dispatch(setEditor(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartDXData]);
};
