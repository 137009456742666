import { createAsyncThunk } from '@reduxjs/toolkit';

import { documentApi } from './document.api';
import type { DocumentSavePayload, DocumentSettlementPayload } from './document.model';
import { consumed, modalOpenState, setFinishedLoading, setLoading } from './ui.slice';

export const saveNsendDocument = createAsyncThunk(
  'document/save&sendDocument',
  async (documentData: Omit<DocumentSavePayload & DocumentSettlementPayload, 'silent'>, thunkAPI) => {
    thunkAPI.dispatch(setLoading('SaveNSend'));

    const resultSave = await thunkAPI.dispatch(
      documentApi.endpoints.putDocument.initiate({ ...documentData, silent: true }, { forceRefetch: true }),
    );

    if (resultSave.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'SaveNSend', openState: false }));
      return resultSave;
    }
    thunkAPI.dispatch(consumed('Save'));

    const resultSend = await thunkAPI.dispatch(
      documentApi.endpoints.putSettlement.initiate(documentData, {
        forceRefetch: true,
      }),
    );

    thunkAPI.dispatch(setFinishedLoading('SaveNSend'));

    if (resultSend.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'SaveNSend', openState: false }));
      return resultSend;
    }
    thunkAPI.dispatch(modalOpenState({ modalId: 'SaveNSend', openState: false }));
    thunkAPI.dispatch(consumed('SaveNSend'));

    return { resultSave, resultSend };
  },
);

export const saveNstoreDocument = createAsyncThunk(
  'document/save&storeDocument',
  async (documentData: Omit<DocumentSavePayload & DocumentSettlementPayload, 'silent'>, thunkAPI) => {
    thunkAPI.dispatch(setLoading('SaveNStore'));

    const resultSave = await thunkAPI.dispatch(
      documentApi.endpoints.putDocument.initiate({ ...documentData, silent: true }, { forceRefetch: true }),
    );

    if (resultSave.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'SaveNStore', openState: false }));
      return resultSave;
    }
    thunkAPI.dispatch(consumed('Save'));

    const resultSend = await thunkAPI.dispatch(
      documentApi.endpoints.putSettlement.initiate(documentData, {
        forceRefetch: true,
      }),
    );

    thunkAPI.dispatch(setFinishedLoading('SaveNStore'));

    if (resultSend.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'SaveNStore', openState: false }));
      return resultSend;
    }
    thunkAPI.dispatch(modalOpenState({ modalId: 'SaveNStore', openState: false }));
    thunkAPI.dispatch(consumed('SaveNStore'));

    return { resultSave, resultSend };
  },
);

export const chasingDocument = createAsyncThunk(
  'document/chasingDocument',
  async (documentData: Omit<DocumentSavePayload & DocumentSettlementPayload, 'silent'>, thunkAPI) => {
    thunkAPI.dispatch(setLoading('Chasing'));

    const resultSave = await thunkAPI.dispatch(
      documentApi.endpoints.putDocument.initiate({ ...documentData, silent: true }, { forceRefetch: true }),
    );

    if (resultSave.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'Chasing', openState: false }));
      return resultSave;
    }
    thunkAPI.dispatch(consumed('Save'));

    const resultSend = await thunkAPI.dispatch(
      documentApi.endpoints.putSettlement.initiate(documentData, {
        forceRefetch: true,
      }),
    );

    thunkAPI.dispatch(setFinishedLoading('Chasing'));

    if (resultSend.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'Chasing', openState: false }));
      return resultSend;
    }
    thunkAPI.dispatch(modalOpenState({ modalId: 'Chasing', openState: false }));
    thunkAPI.dispatch(consumed('Chasing'));

    return { resultSave, resultSend };
  },
);

export const cancelChasingDocument = createAsyncThunk(
  'document/cancelChasingDocument',
  async (documentData: Omit<DocumentSavePayload & DocumentSettlementPayload, 'silent'>, thunkAPI) => {
    thunkAPI.dispatch(setLoading('CancelChasing'));

    const resultSave = await thunkAPI.dispatch(
      documentApi.endpoints.putDocument.initiate({ ...documentData, silent: true }, { forceRefetch: true }),
    );

    if (resultSave.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'CancelChasing', openState: false }));
      return resultSave;
    }
    thunkAPI.dispatch(consumed('Save'));

    const resultSend = await thunkAPI.dispatch(
      documentApi.endpoints.putSettlement.initiate(documentData, {
        forceRefetch: true,
      }),
    );

    thunkAPI.dispatch(setFinishedLoading('CancelChasing'));

    if (resultSend.isSuccess === false) {
      thunkAPI.dispatch(modalOpenState({ modalId: 'CancelChasing', openState: false }));
      return resultSend;
    }
    thunkAPI.dispatch(modalOpenState({ modalId: 'CancelChasing', openState: false }));
    thunkAPI.dispatch(consumed('CancelChasing'));

    return { resultSave, resultSend };
  },
);
