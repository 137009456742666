import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import { useDispatch, useSelector } from 'react-redux';

import { IntlProvider } from '@/context/IntlContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Sgb4Spinner } from '@/components/common/Sgb4Spinner';
import { ToasterProvider } from '@/components/common/ToasterProvider';
import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';

import { setCurrentContext, UserContext } from '@/context/UserContext';

import type { GlobalContext } from '@/types/global-context';

import SgDocsComparator from '@/components/routes/Editor/SgDocs/SgDocsComparator';
import { getConfig } from '@/config/config';
import { setCurrentConfig } from '@/store/editor.slice';
import { selectRights, setOrigin, setRights } from '@/store/ui.slice';

const LandingLazy = lazy(() =>
  import('@/components/routes/Landing').then(({ LandingPage }) => ({
    default: LandingPage,
  })),
);

const ReviewPageLazy = lazy(() =>
  import('@/components/routes/Editor/common/ReviewPageWrapper').then(({ ReviewPageWrapper }) => ({
    default: ReviewPageWrapper,
  })),
);

const PreviewXoneLazy = lazy(() =>
  import('@/components/routes/Editor/Xone/PreviewXone/PreviewXoneWrapper').then(({ PreviewXoneWrapper }) => ({
    default: PreviewXoneWrapper,
  })),
);

const SgDocPreviewLazy = lazy(() =>
  import('@/components/routes/Editor/SgDocs/SgDocPreview/SgDocPreviewWrapper').then(({ SgDocPreviewWrapper }) => ({
    default: SgDocPreviewWrapper,
  })),
);

const PageNotFoundLazy = lazy(() =>
  import('@/components/routes/PageNotFound').then(({ PageNotFound }) => ({
    default: PageNotFound,
  })),
);

const Review = () => {
  const dispatch = useDispatch();

  dispatch(
    setOrigin({
      isXone: true,
      isOctane: false,
      isKB: false,
    }),
  );
  return <ReviewPageLazy />;
};

const Octane = () => {
  const dispatch = useDispatch();

  dispatch(
    setOrigin({
      isXone: false,
      isOctane: true,
      isKB: false,
    }),
  );

  return <ReviewPageLazy />;
};

const Preview = () => {
  const dispatch = useDispatch();

  dispatch(
    setOrigin({
      isXone: true,
      isOctane: false,
      isKB: false,
    }),
  );
  return <PreviewXoneLazy />;
};

const SgDocPreviewXone = () => {
  const dispatch = useDispatch();

  dispatch(setCurrentConfig({ mode: 'readOnly' }));

  dispatch(
    setOrigin({
      isXone: true,
      isOctane: false,
      isKB: false,
    }),
  );

  return <SgDocPreviewLazy />;
};

const KB = () => {
  const dispatch = useDispatch();

  dispatch(
    setOrigin({
      isXone: false,
      isOctane: false,
      isKB: true,
    }),
  );

  return <ReviewPageLazy />;
};

const PreviewKB = () => {
  const dispatch = useDispatch();

  dispatch(
    setOrigin({
      isXone: false,
      isOctane: false,
      isKB: true,
    }),
  );

  const [canRead, canWrite, _canExportAsWord, canUseSmartDX] = useSelector(selectRights);
  useEffect(() => {
    if (canWrite) {
      dispatch(setRights([canRead, false, false, canUseSmartDX]));
      dispatch(setCurrentConfig({ mode: 'readOnly' }));
    }
  }, [canRead, canUseSmartDX, canWrite, dispatch]);

  return <PreviewXoneLazy />;
};

export function App({ sgConnect }: { sgConnect: SGWTConnectCore }) {
  const globalContext: GlobalContext = {
    clientScopeCode: 'SCP0',
    setClientScope: () => undefined,
    permissions: null,
    sgConnect,
    user: null,
    appConfig: getConfig(),
  };

  setCurrentContext(globalContext);

  const location = window.location;
  const mainBackground = location.pathname === '/' ? '' : ' bg-lvl4';

  return (
    <div id="main" className={`vh-100${mainBackground}`}>
      <IntlProvider>
        <UserContext.Provider value={{ globalContext }}>
          <ToasterProvider>
            <BrowserRouter basename={getConfig().publicBaseUrl}>
              <Header />

              <Suspense
                fallback={
                  <div className="m-5 d-flex justify-content-center">
                    <Sgb4Spinner />
                  </div>
                }
              >
                <Routes>
                  {/*
                        Path: landing page
                        Description: pretty much does nothing, just a landing page with that takes to two different routes: Preview or Review
                      */}
                  <Route path="/*" element={<LandingLazy />} />
                  {/*
                        Description: review page for users coming from X-One, allows users to edit a document
                        Params:
                          :docType: document type (i.e. Confirmation, TermSheet...)
                          :assetClass: asset class (i.e. IRD, ENU...)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                          :docId: document id (i.e. 0123456789)
                      */}
                  <Route path="/xone/:docType/:assetClass/:docId/:tradeRef" element={<Review />} />
                  {/*
                        Description: preview page for users coming from X-One, does not allow users to edit a document
                        Params:
                          :docType: document type (i.e. Confirmation, TermSheet...)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                          :docId: document id (i.e. 0123456789)
                      */}
                  <Route path="/Preview/xone/:docType/:tradeRef/:docId" element={<Preview />} />
                  {/*
                        Description: review page for users coming from Enterprise, allows users to edit a document
                        Params:
                          :docType: document type (i.e. Confirmation, TermSheet...)
                          :assetClass: asset class (i.e. IRD, ENU...)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                          :docId: document id (i.e. 0123456789)
                      */}
                  <Route path="/ent/:docType/:assetClass/:docId/:tradeRef" element={<Review />} />
                  {/*
                        Description: review page for users coming from Octane, allows users to edit a document
                        Params:
                          :codent: enterprise code (i.e. SG)
                          :codtrs: document id (i.e. 0123456789)
                          :chasing: chasing type (i.e. Reminder, Out_of_Target...)
                          :chasingDate: chasing date (i.e. 21_02_24_12_00_00_AM)
                          :client: client to be chased (i.e. TOTAL)
                      */}
                  <Route path="/octane/CHASING/:codent/:codtrs/:chasing/:chasingDate/:client" element={<Octane />} />
                  {/*
                        Description: review page for users coming from Octane, allows users to edit a document
                        Params:
                          :docType: document type (i.e. Confirmation, TermSheet...)
                          :assetClass: asset class (i.e. IRD, ENU...)
                          :docId: document id (i.e. 0123456789)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                      */}
                  <Route path="/octane/:docType/:assetClass/:docId/:tradeRef" element={<Octane />} />
                  {/*
                        Description: preview page for documents from SG Docs
                        Params:
                          :sgDocsId: document id (i.e. 0123456789)
                      */}
                  <Route path="/Preview/xone/Sgdoc/:sgDocsId" element={<SgDocPreviewXone />} />
                  {/*
                        Description: dedicated page to compare two SG Docs documents
                        Params:
                          :sgDocsId: document id (i.e. 0123456789)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                          :docType: document type (i.e. Confirmation, TermSheet...)
                      */}
                  <Route path="/showDiff/:sgDocsId/:tradeRef" element={<SgDocsComparator />} />
                  {/*
                        Description: dedicated page for KB users to edit a document
                        Params:
                          :docType: document type (i.e. Confirmation, TermSheet...)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                          :docId: document id (i.e. 0123456789)
                      */}
                  <Route path="/kb/:docType/:assetClass/:docId/:tradeRef" element={<KB />} />
                  {/*
                        Description: dedicated page for KB users to preview a document
                        Params:
                          :docType: document type (i.e. Confirmation, TermSheet...)
                          :tradeRef: trade reference (i.e. ENU-1234567)
                          :docId: document id (i.e. 0123456789)
                      */}
                  <Route path="/Preview/kb/:docType/:tradeRef/:docId" element={<PreviewKB />} />
                  {/*
                        Description: takes the user to a page to open a document with edit rights (as opposed to the one below)
                      */}
                  <Route path="/review/*" element={<Review />} />
                  {/*
                        Description: takes the user to a page to open a document but with no edit rights (as opposed to the one above)
                      */}
                  <Route path="/preview/*" element={<Preview />} />
                  {/*
                        Description: a good old 404 page
                      */}
                  <Route element={<PageNotFoundLazy />} />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </ToasterProvider>
          <Footer />
        </UserContext.Provider>
      </IntlProvider>
    </div>
  );
}
