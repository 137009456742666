export type DocumentTypes =
  | 'Confirmation'
  | 'PreConfirmation'
  | 'ReadOnlyDocument'
  | 'Chasing'
  | 'Affirmation'
  | 'PreMatching'
  | 'FlowAdvice'
  | 'FlowCorrespondence'
  | 'IssuingDocumentation'
  | 'Invoice'
  | 'MRB'
  | 'TermSheet'
  | 'Payment'
  | 'PaymentReminder'
  | 'Fixing'
  | 'FinalTerms';

const isSubConfirm = (docType: string | undefined, current: string) =>
  current === 'Confirmation' &&
  [
    'AccountingLetter',
    'AdmissionLetter',
    'DecisionLetter',
    'MarketingMaterial',
    'Notice',
    'RegReportBAS100',
    'RegReportBAS110',
  ]
    .map((s) => s.toLowerCase())
    .includes(docType ?? '');

const isFlowAdvice = (docType: string | undefined, current: string) =>
  current === 'FlowAdvice' && ['Payment', 'Fixing'].map((s) => s.toLowerCase()).includes(docType ?? '');

export const useDocType = (doctype?: string) =>
  [
    'Confirmation',
    'PreConfirmation',
    'ReadOnlyDocument',
    'Chasing',
    'Affirmation',
    'PreMatching',
    'FlowAdvice',
    'FlowCorrespondence',
    'IssuingDocumentation',
    'Invoice',
    'MRB',
    'TermSheet',
    'FinalTerms',
    'Payment',
    'PaymentReminder',
    'Fixing',
  ].map(
    (type) =>
      type.toLowerCase() === doctype?.toLowerCase() ||
      isSubConfirm(doctype?.toLowerCase(), type) ||
      isFlowAdvice(doctype?.toLowerCase(), type),
  );

export const useDocTypeObj = (doctype?: string) =>
  (
    [
      'Confirmation',
      'PreConfirmation',
      'ReadOnlyDocument',
      'Chasing',
      'Affirmation',
      'PreMatching',
      'FlowAdvice',
      'FlowCorrespondence',
      'IssuingDocumentation',
      'Invoice',
      'MRB',
      'TermSheet',
      'FinalTerms',
      'Payment',
      'PaymentReminder',
      'Fixing',
    ] as Array<DocumentTypes>
  ).reduce(
    (acc, type) => {
      acc[type] =
        type.toLowerCase() === doctype?.toLowerCase() ||
        isSubConfirm(doctype?.toLowerCase(), type) ||
        isFlowAdvice(doctype?.toLowerCase(), type);
      return acc;
    },
    {} as Record<DocumentTypes, boolean>,
  );
