import type { TrackEventParams } from '@datapunt/matomo-tracker-react/lib/types';
import type { IntlShape } from 'react-intl';

import type { ToastPusher } from '@/context/ToasterContext';
import { logger } from '@/helpers/logger';
import { DocumentService } from '@/services/DocumentService';
import type { FileInfo } from '@/store/document.slice';
import type { SmartEditor } from '@/types/SmartCommsApi';
import type { GlobalContext } from '@/types/global-context';

export type Exportable = 'xml' | 'pdf' | 'html' | 'doc' | 'txt';

const prepareToDownload = () => {
  // creates a closure to avoid adding <a> on every download
  const element = document.createElement('a');
  element.id = 'export-document-download-link';
  document.body.appendChild(element);

  return (blob: Blob, fileName: string) => {
    element.href = URL.createObjectURL(blob);
    element.download = fileName;
    element.click();
  };
};

export const download = prepareToDownload();

export const exportFileAsync = async (
  values: Exportable,
  file: FileInfo,
  editor: SmartEditor | undefined,
  currentContent: string | undefined,
  {
    pushToast,
    intl,
    trackEvent,
    globalContext,
  }: {
    pushToast: ToastPusher;
    intl: IntlShape;
    trackEvent: (params: TrackEventParams) => void | undefined;
    globalContext: GlobalContext;
  },
) => {
  if (!editor) {
    pushToast({
      text: intl.formatMessage({ id: 'notification.export.failure' }),
      level: 'danger',
      icon: 'error_outline',
      type: 'alert',
    });
    return;
  }

  try {
    if (!currentContent) {
      logger.logWarning(
        'Failed to export document, draftXML is empty {tradeRef} {docId}',
        file?.tradeReference,
        file?.documentId,
      );
      return;
    }

    trackEvent({ category: 'Action', action: `Document exported as .${values} file` });

    switch (values) {
      case 'pdf': {
        const previewBlob = await DocumentService.previewDocument(
          new AbortController().signal,
          globalContext,
          file?.documentType || 'Confirmation',
          currentContent,
        );
        download(previewBlob, `${file?.documentType}_${file?.tradeReference}.pdf`);
        break;
      }
      case 'txt': {
        const previewBlob = await DocumentService.previewDocument(
          new AbortController().signal,
          globalContext,
          file?.documentType,
          currentContent,
        );
        download(previewBlob, `${file?.documentType}_${file?.tradeReference}.txt`);
        break;
      }
      case 'doc': {
        const fileBlobI = await DocumentService.getDocument(
          new AbortController().signal,
          globalContext,
          file?.tradeReference,
          file?.documentId ?? '',
          file?.documentType,
        );
        if (fileBlobI.originalAttachment) {
          download(
            new Blob([Buffer.from(fileBlobI.originalAttachment, 'base64')], {
              type: 'application/msword',
            }),
            `${file?.documentType}_${file?.tradeReference}.doc`,
          );
        }
        break;
      }
      default: {
        const fileBlobD = new Blob([currentContent], { type: 'text/xml' });
        download(fileBlobD, `${file?.documentType}_${file?.tradeReference}.${values}`);
        break;
      }
    }
    pushToast({
      text: intl.formatMessage({ id: 'notification.export.success' }),
      level: 'info',
      icon: 'info_outline',
    });
  } catch (e) {
    pushToast({
      text: intl.formatMessage({ id: 'notification.export.failure' }),
      level: 'danger',
      icon: 'error_outline',
      type: 'alert',
    });
  }
};

type Enabled = true | false;

export const getExportDropValues = (
  documentType: string,
  displayWord = false,
): { type: Exportable; enabled: Enabled }[] => {
  const isRegReport = documentType === 'RegReportBAS100' || documentType === 'RegReportBAS110';

  return [
    { type: 'doc', enabled: displayWord },
    { type: 'html', enabled: true },
    { type: 'pdf', enabled: !isRegReport },
    { type: 'txt', enabled: isRegReport },
    { type: 'xml', enabled: true },
  ];
};
