export interface IModalProps {
  open?: boolean;
  children: React.ReactNode;
}

export const Modal = ({ open, children }: IModalProps) => (
  <div
    id="default_modal"
    className={`modal ${open ? '' : 'fade'}`}
    tabIndex={-1}
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="false"
    style={{ display: open ? 'block' : undefined }}
  >
    {children}
  </div>
);
