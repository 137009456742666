import { LoadingRenderComponent } from '@/components/common/LoadingRenderComponent';
import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { useLoadSmartDxEditor } from '@/components/routes/Editor/SgDocs/useLoadSmartDxEditor';
import { EditorHeader } from '@/components/routes/Editor/Xone/Header';
import { EditorAnchor } from '@/helpers/SmartDXWrap';
import { useGetSgDocDocumentQuery } from '@/store/document.api';
import { selectEditor, setCompareState } from '@/store/editor.slice';
import type { UrlSgDocComparatorInfo } from '@/types/fileInfos';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const SgDocsComparator = () => {
  useLoadSmartDxEditor([true, false, true, true]);

  const { sgDocsId, tradeRef } = useParams<Readonly<UrlSgDocComparatorInfo>>();
  const { data: sgDocContentDest } = useGetSgDocDocumentQuery({ documentId: sgDocsId });
  const { data: sgDocContentSrc } = useGetSgDocDocumentQuery({ documentId: tradeRef });

  const editor = useSelector(selectEditor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sgDocContentDest && sgDocContentSrc && editor) {
      dispatch(
        setCompareState({
          contentSrc: sgDocContentSrc,
          contentDest: sgDocContentDest,
        }),
      );
    }
  }, [dispatch, sgDocContentDest, editor, sgDocContentSrc]);

  return (
    <>
      <div className="container-fluid px-3 px-lg-4 pb-2 pb-lg-3 border-bottom">
        <div className="row justify-content-start">
          <LoadingRenderComponent isLoading={!editor}>
            <ConcurencyModal />
            <EditorHeader />
          </LoadingRenderComponent>
        </div>
      </div>
      <div>
        <EditorAnchor editorIsLoading={!editor} />
      </div>
    </>
  );
};

export default SgDocsComparator;
