import React from 'react';

import { RightHeader } from '../common/RightHeader';
import { LeftHeader } from './LeftHeader';

export const EditorHeader = () => (
  <>
    <LeftHeader />
    <RightHeader />
  </>
);
