import { type ChangeEventHandler, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import { UserContext } from '@/context/UserContext';
import { useLazySaveOctaneQuery } from '@/store/document.api';
import { selectDocument } from '@/store/document.slice';
import { selectEditor } from '@/store/editor.slice';
import { consumed, modalOpenState, selectModal } from '@/store/ui.slice';

export const getActionFromType = (documenttype: string): ('Save' | 'SaveToValidate' | 'Send' | 'SendEmail')[] => {
  const doctype = documenttype.toLowerCase();

  switch (doctype) {
    case 'confirmation': {
      return ['Save', 'Send'];
    }
    case 'invoice': {
      return ['Save', 'Send'];
    }
    case 'mrb': {
      return ['SendEmail'];
    }
    default: {
      return ['SaveToValidate'];
    }
  }
};

export const selectLocal = (documenttype: string) => {
  let local = '';
  const doctype = documenttype.toLowerCase();

  switch (doctype) {
    case 'confirmation': {
      local = 'modal.octane.validate';
      break;
    }
    case 'invoice': {
      local = 'modal.octane.send';
      break;
    }
    case 'mrb': {
      local = 'modal.octane.sendemail';
      break;
    }
    default: {
      local = 'modal.octane.validate';
    }
  }

  return (type: string, loading?: boolean) => `${local}.${type}${loading ? 'ing' : ''}`;
};

export const SaveOctaneModal = ({ draftXmlDocument }: { draftXmlDocument: string | undefined }) => {
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();
  const open = useSelector(selectModal('SaveOctane'));
  const file = useSelector(selectDocument);
  const editor = useSelector(selectEditor);

  const { sgConnect } = useContext(UserContext).globalContext;

  const [trigger, response] = useLazySaveOctaneQuery();

  const intl = useIntl();

  useEffect(() => {
    if (response.isSuccess) {
      dispatch(modalOpenState({ modalId: 'SaveOctane', openState: false }));
    }
  }, [dispatch, response]);

  useEffect(() => {
    if (!open) {
      setComment('');
    }
  }, [open]);

  const saveNsend = async () => {
    const { assetClass, tradeReference, documentId, documentType } = file;
    const actions = getActionFromType(documentType);

    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSend = editorDocument?.draftXML ?? draftXmlDocument;

    if (assetClass === '' || tradeReference === '' || !documentId || documentType === '') {
      return;
    }

    if (!draftXmlDocumentToSend) {
      return;
    }

    const res = await trigger({
      assetClass,
      tradeReference,
      documentId,
      documentType,
      action: actions[0],
      actionComment: comment,
      workingDocument: draftXmlDocumentToSend,
      userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
    });

    if (actions[1] && res.isSuccess) {
      await trigger({
        assetClass,
        tradeReference,
        documentId,
        documentType,
        action: actions[1],
        actionComment: comment,
        workingDocument: draftXmlDocumentToSend,
        userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
      });
    }
    dispatch(consumed('SendOctane'));
  };

  const onCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.currentTarget.value !== comment) {
      setComment(event.currentTarget.value);
    }
  };

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max">
          <div className="modal-header d-flex align-items-center p-2">
            <h3 className="modal-title pl-3 pt-2">
              <FormattedMessage id={selectLocal(file.documentType)('send', false)} />
            </h3>
            <button
              type="button"
              className="btn btn-xl btn-flat-secondary ml-auto"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(modalOpenState({ modalId: 'SaveOctane', openState: false }))}
            >
              <i className="icon">close</i>
            </button>
          </div>
          <div className="modal-body px-4 pb-0">
            <div>
              <FormattedMessage id={selectLocal(file.documentType)('text', false)} />
            </div>
            <div className="mt-3">
              <label className="text-secondary" htmlFor="modal.send.comment">
                <FormattedMessage id={selectLocal(file.documentType)('comment', false)} />
              </label>
              <textarea
                className="form-control form-control-lg"
                id={selectLocal(file.documentType)('comment', false)}
                placeholder={intl.formatMessage({
                  id: 'modal.send.comment_placeholder',
                })}
                onChange={onCommentChange}
              />
            </div>
          </div>
          <div className="modal-footer p-4">
            <div className="btn-group m-0">
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary m-0 mr-1"
                data-dismiss="modal"
                onClick={() => dispatch(modalOpenState({ modalId: 'SaveOctane', openState: false }))}
              >
                <FormattedMessage id={selectLocal(file.documentType)('cancel', false)} />
              </button>
              <button
                type="button"
                className={`btn btn-lg btn-info m-0 ${response.isLoading ? 'disabled' : ''}`}
                data-dismiss="modal"
                onClick={saveNsend}
              >
                <FormattedMessage id={selectLocal(file.documentType)('send', response.isLoading)} />
                {response.isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm mr-1"
                    style={{ height: '1rem', width: '1rem' }}
                    role="status"
                  />
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
